import { Stack } from "@mui/material";
import { reduxForm } from "redux-form";
import Button from "components/atoms/Button";
import { modalActions } from "redux/slices/modal";
import FieldError from "components/atoms/FieldError";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import ReduxFormFields, { ReduxFormField } from "components/molecules/ReduxFormFields";
import { childrenSeats } from "utils/validate.util";
import SelectRedux from "components/molecules/SelectRedux";
import OptionService from "utils/option.util";
import { useTranslation } from "react-i18next";

function ChildrenSeatsForm({ handleSubmit }: any) {
	const { t } = useTranslation("translation")



	const fields: ReduxFormField[] = [
		{
			name: "one_to_three_years",
			label: t("1-3_years_(9-18kg)"),
			validate: [childrenSeats],
			component: SelectRedux,
			hideError: true,
			SelectProps: {
				options: OptionService.getOptions(3),
			},
			cellProps: { md: 12 },
		},
		{
			name: "three_to_six_years",
			label: t("3-6_years_(15-25kg)"),
			component: SelectRedux,
			SelectProps: {
				options: OptionService.getOptions(3),
			},
			cellProps: { md: 12 },
		},
		{
			name: "six_to_twelve_years",
			label: t("6-12_years_(22-36kg)"),
			component: SelectRedux,
			SelectProps: {
				options: OptionService.getOptions(3),
			},
			cellProps: { md: 12 },
		},
	];

	const form = "ChildrenSeatsForm";
	const dispatch = useAppDispatch();
	const values: any = useAppSelector((state) => state.form?.[form]);
	const error =
		values?.fields &&
		values?.syncErrors &&
		values?.syncErrors?.one_to_three_years;

	return (
		<div>
			<form onSubmit={handleSubmit}>
				<ReduxFormFields fields={fields} />
				<br />
				{error && <FieldError error={error} />}
				<br />
				<Stack direction="row" spacing={2}>
					<Button
						color="secondary"
						variant="outlined"
						onClick={() => dispatch(modalActions.closeModal())}
					>
						{t("Cancel")}
					</Button>
					<Button type="submit" disableElevation variant="contained">
						{t("Save")}
					</Button>
				</Stack>
			</form>
		</div>
	);
}

export default reduxForm({ form: "ChildrenSeatsForm" })(ChildrenSeatsForm);
