export { default } from "./Header";

export interface ILink {
  to: string;
  text: string;
  active: string;
}

export const linksa: ILink[] = [
  { to: "/bookings", text: "Bookings", active: "booking" },
  { to: "/customers", text: "Customers", active: "customer" },
  { to: "/invoices", text: "Invoices", active: "invoice" },
];
