import { ClassesState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: ClassesState = {
  classesPrices: [],
  classsPrice: null,
  loading: false,
  returnTripclassesPrices: [],
  returnTripclasssPrice: null,
  returnTriploading: false,
};

export const classSlice = createSlice({
  name: "classes",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
   
    setClasssPrice: (state, action) => {
      state.classsPrice = action.payload;
    },
    setClassesPrices: (state, action) => {
      const classesPrices = action.payload;
      let classsPrice = classesPrices?.[0];
      
      state.classsPrice = classsPrice;
      state.classesPrices = classesPrices;
    },
    setReturnTripLoading: (state, action: PayloadAction<boolean>) => {
      state.returnTriploading = action.payload;
    },
    setReturnTripClasssPrice: (state, action) => {
      state.returnTripclasssPrice = action.payload;
    },
    setReturnTripClassesPrices: (state, action) => {
      const classesPrices = action.payload;
      let classsPrice = classesPrices?.[0];

      state.returnTripclasssPrice = classsPrice;
      state.returnTripclassesPrices = classesPrices;
    },
  },
});

const classReducer = classSlice.reducer;

export const classActions = classSlice.actions;
export default classReducer;
