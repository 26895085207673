export { default, modalActions, modalSlice } from "./modalSlice";

export enum MODAL {
	HANDLE_STATUS = "HANDLE_STATUS",
	CHILDREN_SEATS = "CHILDREN_SEATS",
	UPDATE_BOOKING = "UPDATE_BOOKING",
	CHANGE_PASSWORD = "CHANGE_PASSWORD",
	CONFIRMATION_FORM = "CONFIRMATION_FORM",
	ADD_PAYMENT="ADD_PAYMENT",
	CONFIRMATION_MODAL="CONFIRMATION_MODAL",
	PAYMENT_CARDS="PAYMENT_CARDS"
}

export type ModalType = "" | keyof typeof MODAL;

export interface OpenModalState {
	data: any;
	width: any;
	type: ModalType;
	loading?: boolean;
}

export type ModalState = OpenModalState & {
	open: boolean;
};
