import { lazy } from "react";

const Login = lazy(() => import("pages/Login"));
const PasswordOTP = lazy(() => import("pages/PasswordOTP"));
const ResetPassword = lazy(() => import("pages/ResetPassword"));
const ForgotPassword = lazy(() => import("pages/ForgotPassword"));

const Profile = lazy(() => import("pages/Profile"));
const Bookings = lazy(() => import("pages/Bookings"));
const Invoices = lazy(() => import("pages/Invoices"));
const Customers = lazy(() => import("pages/Customers"));
const BookingDetails = lazy(() => import("pages/BookingDetails"));
const BookingConfirmation = lazy(() => import("pages/BookingConfirmation"));
const AccountAccess = lazy(() => import("pages/AccountAccess"));
const Wallet = lazy(() => import("pages/Wallet"));

const AddCustomer = lazy(() => import("pages/AddCustomer"));
const AddBooking = lazy(() => import("pages/AddBooking"));
const AddAccountAccess = lazy(() => import("pages/AddAccountAccess"));

export { default } from "./AppRoutes";

export interface IRoute {
	path: string;
	element: JSX.Element;
}

export const public_routes: IRoute[] = [
	{ path: "/", element: <Login /> },
	{ path: "/password-otp", element: <PasswordOTP /> },
	{ path: "/reset-password", element: <ResetPassword /> },
	{ path: "/forgot-password", element: <ForgotPassword /> },
];

export const private_routes: IRoute[] = [
	{ path: "/profile", element: <Profile /> },

	{ path: "/bookings", element: <Bookings /> },
	{ path: "/add-booking", element: <AddBooking /> },
	{ path: "/booking-details/:id", element: <BookingDetails /> },
	{ path: "/booking-confirmation/:id", element: <BookingConfirmation /> },

	{ path: "/invoices", element: <Invoices /> },
	{ path: "/customers", element: <Customers /> },
	{ path: "/add-customer", element: <AddCustomer /> },
	{ path: "/customer-details/:id", element: <AddCustomer /> },

	{ path: "/account-access", element: <AccountAccess /> },
	{ path: "/add-account-access", element: <AddAccountAccess /> },
	{ path: "/account-access/:id", element: <AddAccountAccess /> },
	{ path: "/wallet", element: <Wallet /> },
];
