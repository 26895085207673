import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import EnTranslation from "./en/language.json";
import DeTranslation from "./de/language.json";
import TrTranslation from "./tr/language.json";

const resources = {
  en: {
    translation: EnTranslation,
  },
  de: {
    translation: DeTranslation,
  },
  tr: {
    translation: TrTranslation,
  },
};

i18next.use(initReactI18next).init({
  resources,
  // lng: "ar",
  lng: localStorage.getItem("lang") ?? navigator.language,
  debug: true,
  interpolation: {
    escapeValue: false // not needed for react as it escapes by default
  }

});
export default i18next;
