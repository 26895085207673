import "./Header.css";
import logo from "assets/logo-light.png";
import { Container, Grid, Stack } from "@mui/material";
import AuthService from "services/auth.service";
import { useAppSelector } from "redux/hooks";
import BasicMenu from "components/atoms/BasicMenu/BasicMenu";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaAngleDown } from "react-icons/fa";
import { ILink } from ".";
import { useEffect, useState } from "react";

export default function Header() {
	// ** Hools
	const { t, i18n } = useTranslation("translation");
	const location = useLocation();
	const navigate = useNavigate();
	const { pathname } = location;

	// ** States
	const user = useAppSelector((state) => state.auth.user);

	const [links, setLinks] = useState<ILink[]>([
		{ to: "/bookings", text: "Bookings", active: "booking" },
		{ to: "/customers", text: "Customers", active: "customer" },
		{ to: "/invoices", text: "Invoices", active: "invoice" },
		{ to: "/account-access", text: "account_access", active: "account-access" },
	])


	useEffect(() => {
		if (!user) return;
		if (user?.company_id?.isWalletEnable || user?.isWalletEnable) {
			setLinks((prev) => [...prev, { to: "/wallet", text: "wallet", active: "wallet" }])
		}

		// AuthService.splash();
		// OneSignalService.connecuser._d);
		// SocketService.connect(user);
		return () => {
			setLinks(
				[
					{ to: "/bookings", text: "Bookings", active: "booking" },
					{ to: "/customers", text: "Customers", active: "customer" },
					{ to: "/invoices", text: "Invoices", active: "invoice" },
					{ to: "/account-access", text: "account_access", active: "account-access" },
				]
			)
		}
	}, [user]);

	// useEffect(() => {
	// 	if (!user || user?.passwordChangeAt) return;

	// 	dispatch(
	// 		modalActions.openModal({
	// 			type: MODAL.CHANGE_PASSWORD,
	// 			data: "",
	// 			width: "500px",
	// 		})
	// 	);
	// }, [user, dispatch]);

	const changeLanguage = (lang: any) => {
		localStorage.setItem("lang", lang)
		i18n.changeLanguage(lang);
	};

	if (
		!user ||
		pathname === "/" ||
		pathname === "/password-otp" ||
		pathname === "/reset-password" ||
		pathname === "/forgot-password"
	)
		return <></>;

	return (
		<header>
			<div className="navbar">
				<Container>
					<Grid container alignItems="center">
						<Grid item xs={12} md={3}>
							<Link to="/bookings">
								<div className="logo">
									<img
										src={logo}
										// src={user?.profile_logo?.url || logo}
										alt="logo"
										loading="lazy"
									/>
								</div>
							</Link>
						</Grid>
						<Grid item xs={12} md={9}>
							<ul>
								{links.map(({ to, text, active }, i) => (
									<li key={i}>
										<Link
											to={to}
											className={pathname.includes(active) ? "active" : ""}
										>
											{t(text)}
										</Link>
									</li>
								))}
								<li className="list-item">
									<span className="item">
										<BasicMenu
											list={[
												{
													text: "EN",
													onClick: () => {
														changeLanguage("en");
													},
												},
												{
													text: "DE",
													onClick: () => changeLanguage("de"),
												},
												{
													text: "TR",
													onClick: () => changeLanguage("tr"),
												},
											]}
											avatar={false}
											color="#ffffff"
										>
											<Stack
												direction="row"
												alignItems="center"
												columnGap={1}
											>
												{i18n.language === "en"
													? "EN"
													: i18n.language === "de"
														? "DE"
														: i18n.language === "tr"
															? "TR"
															: "EN"}
												<FaAngleDown />
											</Stack>
										</BasicMenu>
									</span>
								</li>
								<li>
									<div style={{ padding: "10px 0" }}>
										<BasicMenu
											text={
												user.first_name.charAt() + user.last_name.charAt()
											}
											list={[
												{
													text: user.first_name + " " + user.last_name,
													onClick: () => navigate("/profile"),
												},
												{
													text: t("logout"),
													onClick: () => AuthService.logout(),
												},
											]}
											avatar={true}

										/>
									</div>
								</li>
							</ul>
						</Grid>
					</Grid>
				</Container>
			</div>
		</header>
	);
}
