import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccountUserState } from ".";

const initialState: AccountUserState = {
	accountUsers: [],
	accountUser: null,
	loading: false,
};

export const accountsSlice = createSlice({
	name: "accounts",
	initialState,
	reducers: {
		
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		// addCustomer: (state, action) => {
		// 	state.customers.push(action.payload);
		// },
		// updateCustomer: (state, action) => {
		// 	const { id, customer } = action.payload;

		// 	state.customers.every(({ _id }, i) => {
		// 		if (id === _id) {
		// 			state.customers[i] = customer;
		// 			return false;
		// 		}

		// 		return true;
		// 	});
		// },
		setAccountUsers: (state, action) => {
			state.accountUsers = action.payload;
		},
		setAccountUser: (state, action) => {
			state.accountUser = action.payload;
		},
	},
});

const accountsReducer = accountsSlice.reducer;

export const accountsActions = accountsSlice.actions;
export default accountsReducer;
